var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user && _vm.user.user_type.indexOf("admin") !== -1
    ? _c(
        "a-menu",
        { attrs: { theme: "dark", mode: "inline" } },
        [
          _c(
            "a-menu-item-group",
            { key: "g2", staticClass: "gx-menu-group" },
            [
              _c(
                "a-menu-item",
                { key: "admin_dashboard", on: { click: _vm.hideSidebar } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/dashboard" } },
                    [
                      _c("a-icon", { attrs: { type: "home" } }),
                      _c("span", [_vm._v("Dashboard")]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "users", on: { click: _vm.hideSidebar } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/users" } },
                    [
                      _c("a-icon", { attrs: { type: "user" } }),
                      _c("span", [_vm._v("Club Admins")]),
                      _c("LeftOutlined"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "settings", on: { click: _vm.hideSidebar } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/admin/settings" } },
                    [
                      _c("a-icon", { attrs: { type: "solution" } }),
                      _c("span", [_vm._v("Settings")]),
                      _c("LeftOutlined"),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-menu-item",
                { key: "logout", on: { click: _vm.Logout } },
                [
                  _c("a-icon", { attrs: { type: "logout" } }),
                  _c("span", [_vm._v("Logout")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }