var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-menu",
    { attrs: { theme: "dark", mode: "inline" } },
    [
      _c(
        "a-menu-item-group",
        { key: "g2", staticClass: "gx-menu-group" },
        [
          _c(
            "a-menu-item",
            { key: "dashboard", on: { click: _vm.hideSidebar } },
            [
              _c(
                "router-link",
                { attrs: { to: "/dashboard" } },
                [
                  _c("a-icon", { attrs: { type: "home" } }),
                  _c("span", [_vm._v("My Events")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "members", on: { click: _vm.hideSidebar } },
            [
              _c(
                "router-link",
                { attrs: { to: "/members" } },
                [
                  _c("a-icon", { attrs: { type: "smile" } }),
                  _c("span", [_vm._v("Members")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "teams", on: { click: _vm.hideSidebar } },
            [
              _c(
                "router-link",
                { attrs: { to: "/teams-list" } },
                [
                  _c("a-icon", { attrs: { type: "team" } }),
                  _c("span", [_vm._v("Teams")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm.user.select_role === "club_admin"
            ? _c(
                "a-menu-item",
                { key: "admins", on: { click: _vm.hideSidebar } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/admin-list" } },
                    [
                      _c("a-icon", { attrs: { type: "smile" } }),
                      _c("span", [_vm._v("Coaches")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.user.select_role === "club_admin"
            ? _c(
                "a-menu-item",
                { key: "subscription", on: { click: _vm.hideSidebar } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/subscription" } },
                    [
                      _c("a-icon", { attrs: { type: "euro" } }),
                      _c("span", [_vm._v("Subscription")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.user.select_role != "coach"
            ? _c(
                "a-menu-item",
                { key: "club_config", on: { click: _vm.hideSidebar } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/club/settings/my-club" } },
                    [
                      _c("a-icon", { attrs: { type: "setting" } }),
                      _c("span", [_vm._v("Club Details")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.user.select_role == "coach"
            ? _c(
                "a-menu-item",
                { key: "club_config", on: { click: _vm.hideSidebar } },
                [
                  _c(
                    "router-link",
                    { attrs: { to: "/clubs-list" } },
                    [
                      _c("a-icon", { attrs: { type: "setting" } }),
                      _c("span", [_vm._v("Clubs")]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c(
            "a-menu-item",
            { key: "my_profile", on: { click: _vm.hideSidebar } },
            [
              _c(
                "router-link",
                { attrs: { to: "/my-profile" } },
                [
                  _c("a-icon", { attrs: { type: "user" } }),
                  _c("span", [_vm._v("My Profile")]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "a-menu-item",
            { key: "logout", on: { click: _vm.Logout } },
            [
              _c("a-icon", { attrs: { type: "logout" } }),
              _c("span", [_vm._v("Logout")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }